import axios from "axios";

//const botHost = 'https://bbot.zxspectr.ru/bot/api.php?key=dnaksjdmMdn987&';
const botHost = 'https://procupon.ru/bot/api.php?key=dnaksjdmMdn987&';

export function useSiteApi() {
    /**
     * Информация о магазине и купоны
     *
     * @param id ID магазина
     * @returns {Promise<any>}
     */
    const getStoreInfo = async (id) => {
        const result = await axios(
            //botHost + '/store' + id + '-coupon.json'
            botHost + 'action=store/view&id=' + id
        );
        console.debug(result.data);
        return result.data;
    };


    /**
     * Список магазинов
     *
     * @returns {Promise<any>}
     */
    const getStoreList = async () => {
        const result = await axios(
            //botHost + '/store-list.json'
            botHost + 'action=store/index'
        );
        console.debug(result);

        return result.data;
    };

    return {
        getStoreInfo,
        getStoreList
    };
}