import React from "react";

const AboutInfo = () => {

    return (
        <div>
            <div className={'page-body'}>
                <div className={'store-info'}>
                    <h1 className={'store-title'}>О нас</h1>
                </div>
                <div className={'store-description'}>


                        <p>Проект ProCupon – ваш персональный проводник в мир выгодных покупок и экономии. Это
                            уникальная платформа, предоставляющая доступ к множеству скидок и бесплатных промокодов на
                            различные интернет-магазины и сервисы.</p>


                        <p>Процесс использования ProCupon прост и интуитивно понятен. Пользователи могут легко находить
                            актуальные предложения и эксклюзивные промокоды для своих любимых онлайн-магазинов, получая
                            возможность значительно сэкономить при покупках. Этот проект предоставляет удобный
                            инструмент для тех, кто ценит свое время и финансы.</p>


                        <p>Основные преимущества ProCupon включают в себя:</p>


                        <ol>
                            <li><strong>Широкий спектр предложений:</strong> ProCupon сотрудничает с различными
                                интернет-магазинами и сервисами, предоставляя пользователям множество вариантов для
                                экономии на покупках.
                            </li>


                            <li><strong>Бесплатные промокоды:</strong> Пользователи могут находить и использовать
                                бесплатные промокоды, снижая стоимость товаров и услуг без лишних затрат.
                            </li>


                            <li><strong>Актуальность информации:</strong> Промокоды и скидки регулярно обновляются, что
                                обеспечивает актуальность представленной информации.
                            </li>


                            <li><strong>Простота использования:</strong> Интуитивно понятный интерфейс и удобный поиск
                                делают процесс поиска скидок максимально эффективным.
                            </li>


                            <li><strong>Эксклюзивные предложения:</strong> ProCupon предоставляет доступ к уникальным
                                предложениям, которые недоступны в общем доступе.
                            </li>
                        </ol>


                        <p>Присоединяйтесь к ProCupon и превратите свои покупки в увлекательное приключение, наполненное
                            выгодами и экономией. Откройте для себя новые возможности экономии с проектом, который ценит
                            ваш бюджет так же, как и вы.</p>
                    <small className={'description'}>
                        ИП Новиков К.В., ИНН: 545112209908, ОГРНИП: 324547600003751, г.Новосибирск ул. Кубовая 113/2, тел +7
                        (993) 971-41-49
                    </small>
                </div>
            </div>
        </div>
    )
}

export default AboutInfo;