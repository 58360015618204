import './SearchInput.css';

const SearchInput = ({onChangeHandler}) => {

    const onChange = (e) => {
        onChangeHandler(e.target.value);
    }

    return (
        <div className={'search-box page-body'}>
            <input type="text" onChange={onChange} placeholder={'Поиск'}/>
        </div>
    );
}

export default SearchInput;