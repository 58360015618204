import './CouponItem.css';
import {useState} from "react";
import {useTelegram} from "../../hooks/useTelegram";

const CouponItem = ({coupon}) => {
    const [copySuccess] = useState('');
    const {tg} = useTelegram();


    const openLink = (url) => {
        try {
            window.ym(97161358,'reachGoal','openCoupon');
        } catch (e) {
            console.error(e);
        }
        try {
            window._tmr.push({ type: 'reachGoal', id: 3529472, goal: '25062024'});
        } catch (e) {
            console.error(e);
        }
        tg.openLink(url);
    }

    const copyToClipboard = (code, link) => {
        /*var textField = document.createElement('textarea')
        textField.innerText = code
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove();
        setCopySuccess('Copied!');*/
        openLink(link);
    }


    return (
        <div className={'coupon-item'}>
            <h3>{coupon.title}</h3>
            <small className={'description'}>{coupon.description}</small>
            <div className={'promocode-box'}>
                <div className={'coupon-info'}>
                    {/*<span className={'label'}>
                        Промокод:<br/>
                        <span className={'code'}>
                            {coupon.code != '' ? coupon.code : 'Не требуется'}
                        </span>
                    </span>*/}
                    <span className={'date'}>{coupon.expiresText}</span>
                </div>
            </div>

            <button className="btn-main" onClick={() => copyToClipboard(coupon.code, coupon.partnerLink)}>Открыть</button>
            {copySuccess}
        </div>
    )
 }

export default CouponItem;