import React, {useEffect, useState} from 'react';
import './StoreList.css';
import StoreItem from "../StoreItem/StoreItem";
import {useSiteApi} from "../../hooks/useSiteApi";
import SearchInput from "../SearchInput/SearchInput";

const StoreList = () => {
    const {getStoreList} = useSiteApi();
    const [stores, setStores] = useState([]);
    const [filteredStores, setFilteredStores] = useState([]);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getStoreList();
            setStores(data);
            setFilteredStores(data);
            setReady(true);
        }
        if (!ready) {
            fetchData().then(() => {});
        }
    });



    const onChangeHandler = (value) => {
        console.debug(value);
        if (value.length === 0) {
            setFilteredStores(stores);
            return;
        }
        let filtered = [];
        stores.forEach((item) => {
            if (item.title.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
                filtered.push(item);
            }
        });

        setFilteredStores(filtered);
    }

    return (
        <div>
            <SearchInput onChangeHandler={onChangeHandler}/>
            <ul className={'store-list'}>
                {
                    filteredStores.map(item => (<StoreItem store={item} className={'item'} key={item.id}/>))
                }
            </ul>
        </div>
    );
};

export default StoreList;