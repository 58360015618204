import React from 'react';
/*import Button from "../Button/Button";*/
import './StoreItem.css';

const StoreItem = ({store, className, onAdd}) => {

/*    const onAddHandler = () => {
        onAdd(store); //
    }*/

    return (
        <li className={'store-item ' + className}>
            <div className={'content'}>
                <a className={'link'} href={'/store/' + store.id}>
                    <img className="store-image" src={store.img}
                         alt="Мегамаркет"/>
                    <div className="info">
                        <span className="store-name">{store.title}</span>
                        <span className="store-category">{store.category}</span>
                    </div>
                </a>
            </div>
            <div className="line"></div>
            {/*<div className={'img'}/>
            <div className={'title'}>{store.title}</div>
            <div className={'description'}>{store.description}</div>
            <div className={'price'}>
                <span>Стоимость: <b>{store.price}</b></span>
            </div>
            <Button className={'add-btn'} onClick={onAddHandler}>
                Получить промокод
            </Button>*/}
        </li>
    );
};

export default StoreItem;