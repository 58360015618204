import {useEffect} from "react";
import { useNavigate } from "react-router-dom";

const tg = window.Telegram.WebApp;
export function useTelegram() {
    const navigate = useNavigate();
    useEffect(() => {
        tg.ready();
    }, []);

    const onClose = () => {
        tg.close()
    }

    const onToggleButton = () => {
        if(tg.MainButton.isVisible) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    }

    const onShowBackButtonVisible = (state) => {
        if (state) {
            tg.BackButton.show();
        } else {
            tg.BackButton.hide();
        }

    }

    const backButtonDefaultClickInit = () => {

        tg.onEvent('backButtonClicked', () => {
            tg.BackButton.hide();
            tg.offEvent('backButtonClicked');
            navigate(-1);

        });
    }

    return {
        onClose,
        onToggleButton,
        onShowBackButtonVisible,
        backButtonDefaultClickInit,
        tg,
        user: tg.initDataUnsafe?.user,
        //queryId: tg.initDataUnsafe?.query_id,
    }
}