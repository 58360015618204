import './App.css';
import {useEffect} from "react";
import {useTelegram} from "./hooks/useTelegram";
/*import Header from "./components/Header/Header";*/
import {Route, Routes} from 'react-router-dom'
import StoreList from "./components/StoreList/StoreList";
import StoreInfo from "./components/StoreInfo/StoreInfo";
import AboutInfo from "./components/AboutInfo/AboutInfo";

function App() {
  const {tg} = useTelegram();
  useEffect(() => {
    tg.ready();
  })

  return (
    <div className="App">
      {/*<Header />*/}
      <Routes>
        <Route index element={<StoreList />}/>
        <Route path={'/store/:id'} element={<StoreInfo />} />
        <Route path={'/about'} element={<AboutInfo />} />
      </Routes>
    </div>
  );
}

export default App;
