import React, {useEffect, useState} from "react";
import './StoreInfo.css';
import {useParams} from "react-router-dom";
import {useTelegram} from "../../hooks/useTelegram";
import {useSiteApi} from "../../hooks/useSiteApi";
import CouponItem from "../CouponItem/CouponItem";

const StoreInfo = () => {
    const {onShowBackButtonVisible, backButtonDefaultClickInit} = useTelegram();
    const {getStoreInfo} = useSiteApi();
    const params = useParams();
    const [storeInfo, setStoreInfo] = useState([]);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        backButtonDefaultClickInit();
        onShowBackButtonVisible(true);
    });

    useEffect(() => {
        const fetchData = async () => {

            const data = await getStoreInfo(params?.id);
            setStoreInfo(data);
            setReady(true);
        }
        if (!ready) {
            fetchData().then(() => {});
        }

    });
    return (
        <div>
            <div className={'page-body'}>
                <div className={'store-info'}>
                    <div>
                        <img className="store-image" src={storeInfo.img}
                             alt={storeInfo.title}/>
                    </div>
                    <h1 className={'store-title'}>{storeInfo.title}</h1>
                </div>
                <div className={'store-description'}>{storeInfo.description}</div>

                <div>
                    {
                        storeInfo?.coupons?.map(
                            couponItem => (<CouponItem coupon={couponItem} key={couponItem.id}/>)
                        )
                    }
                </div>

            </div>

        </div>
    );
};


export default StoreInfo;